export const dataAmonestaciones = [
  {
    id: 1,
    numCedula: 5,
    pdfUrl: "5.pdf", 
  },
  {
    id: 2,
    numCedula: 38,
    pdfUrl: "38.pdf", 
  },
];

export const dataEstrados = [
  // {
  //   id: 1,
  //   numCedula: 8,
  //   folio: "0848-2025", 
  //   pdfMedio: "0848-2025-1.pdf", 
  //   pdfCedula: "0848-2025-2.pdf", 
  // },
];


export const dataConvocatorias = [
  // {
  //   id: 1,
  //   nameFile: "Convocatoria-Licitacion-Publica-Nacional-ITE-01-2024",
  //   linkFile:
  //     "https://itetlax.org.mx/assets/pdf/convocatorias/Convocatoria-Licitacion-Publica-Nacional-ITE-01-2024.pdf",
  // },
];

export const dataConvocatoriasApartado = [
  {
    id: 1,
    nameFile: "Convocatoria Licitacion ITE-LP-06-2024",
    linkFile:
      "https://itetlax.org.mx/assets/pdf/convocatorias/Convocatoria-ITE-LP-06-2024.pdf",
  },
];

export const dataInformes = [
  {
    mes: "Marzo",
    titulo:
      "INFORME DE LA SECRETARÍA EJECUTIVA RESPECTO DE LA RECEPCIÓN DE LOS LISTADOS Y EXPEDIENTES DIGITALES DE LAS PERSONAS CANDIDATAS DEL PROCESO ELECTORAL LOCAL EXTRAORDINARIO 2024-2025.",
    linkFile: "https://itetlax.org.mx/assets/pdf/informes/1.pdf",
  },

  {
    mes: "Marzo",
    titulo:
      "LISTADO DE MAGISTRADOS Y JUECES QUE VAN DIRECTO A LA BOLETA Y LAS PERSONAS CANDIDATAS QUE PASARON LA INSACULACIÓN” ENTREGADO POR EL CONGRESO DEL ESTADO DE TLAXCALA AL ITE DÍA 13 DE MARZO DE 2025.",
    linkFile: "https://itetlax.org.mx/assets/pdf/carousel/Listado.pdf",
  },
  {
    mes: "Marzo",
    titulo:
      "ACTUALIZACIÓN DE LISTADO REMITIDO POR EL CONGRESO DEL ESTADO DE TLAXCALA EL DÍA 18 DE MARZO DE 2025 AL ITE.",
    linkFile: "https://itetlax.org.mx/assets/pdf/carousel/ListadoActualizado.pdf",
  },
  {
    mes: "Marzo",
    titulo:
      "ACTUALIZACIÓN DE LISTADO (21/MARZO/2025) DERIVADO DE SOLICITUD DE ACLARACIÓN REALIZADA AL CONGRESO DEL ESTADO POR EL ITE.",
    linkFile: "https://itetlax.org.mx/assets/pdf/carousel/Listado21deMarzo.pdf",
  },
  {
    mes: "Abril",
    titulo:
      "ACTUALIZACIÓN DE LISTADO (10/ABRIL/2025) DERIVADO DE LAS SUSTITUCIONES REALIZADAS POR EL CONGRESO DEL ESTADO DE TLAXCALA.",
    linkFile: "https://itetlax.org.mx/assets/pdf/carousel/ListadoCuarto.pdf",
  },
];
