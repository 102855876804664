import { useState } from "react";
import { Link } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import "../../../App.css"; // Importamos los estilos personalizados

const CarouselItemIMG = ({ imgUrl, alt, itemActive, url, linkToo, id }) => {
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  return (
    <>
      <div className={"carousel-item " + itemActive}>
        {id === 6 ? (
          <img
            className="img-fluid"
            src={imgUrl}
            alt={alt}
            style={{ cursor: "pointer" }}
            onClick={handleOpenModal} // Abre el modal al hacer clic
          />
        ) : url ? (
          <a href={url} target="_blank" rel="noreferrer">
            <img className="img-fluid" src={imgUrl} alt={alt} />
          </a>
        ) : linkToo ? (
          <Link to={linkToo}>
            <img className="img-fluid" src={imgUrl} alt={alt} />
          </Link>
        ) : (
          <img className="img-fluid" src={imgUrl} alt={alt} />
        )}
      </div>

      {/* Modal para ID 6 */}
      <Modal show={showModal} onHide={handleCloseModal} dialogClassName="custom-modal">
        <Modal.Header closeButton>
          <Modal.Title>Documentos de Elección Judicial</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Seleccione un documento:</p>
          <ul>
            <li>
              


              <a
                href="https://itetlax.org.mx/assets/pdf/carousel/Listado.pdf"
                target="_blank"
                rel="noreferrer"
                className="modal-link"
              >
                Listado de Magistrados y Jueces que van Directo a la Boleta
                y las Personas Candidatas que pasaron la Insaculación” 
                entregado por el congreso del Estado de Tlaxcala al ITE el día 13 de Marzo de 2025.
              </a>
            </li>
            <li>
            <a
                href="https://itetlax.org.mx/assets/pdf/carousel/ListadoActualizado.pdf"
                target="_blank"
                rel="noreferrer"
                className="modal-link"
              >
                 Actualización de listado remitido por el congreso del estado de Tlaxcala el día
                 18 de Marzo de 2025 al ITE.
              </a>
            </li>


            <li>
            <a
                href="https://itetlax.org.mx/assets/pdf/carousel/Listado21deMarzo.pdf"
                target="_blank"
                rel="noreferrer"
                className="modal-link"
              >Actualización de listado (21/Marzo/2025) derivado de solicitud de aclaración realizada al congreso del estado por el ITE. 
              </a>
            </li>


            <li>
            <a
                href="https://itetlax.org.mx/assets/pdf/carousel/ListadoCuarto.pdf"
                target="_blank"
                rel="noreferrer"
                className="modal-link"
              >
              Actualización de listado (10/abril/2025) derivado de las sustituciones realizadas por el Congreso del Estado de Tlaxcala.
              </a>
            </li>
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CarouselItemIMG;
